import React from "react";
import FeatureCards from "./FeatureCards";
import Footer from "./Footer";
import Header from "./Header";
import Seo from "./Seo";
import Sidebar from "./Sidebar";
import Slider from "./Slider";

const Layout = ({children, hasSlider, hasSidebar = true}) => {

	return (
		<div className="container mx-auto bg-white shadow">
			<Seo />
			<Header />
			{
				hasSlider &&
						<>
							<Slider />
							<FeatureCards />
						</>
			}
			<div className="relative flex flex-col md:flex-row my-12">
				{
					hasSidebar && <Sidebar />
				}
				<div className="w-full min-h-screen pr-12 pt-6">
					{ children }
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default Layout;