import React, { useEffect } from "react";
import Nav from "./Nav";
import logo from "../assets/img/logo.png";
import { Link } from "gatsby";
import { useStateIfMounted } from "use-state-if-mounted";

const Header = () => {

	const [scroll, setScroll] = useStateIfMounted(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 50);
		});
	}, []);

	return (
		<header className={ `sticky top-0 z-50 bg-white flex items-center justify-between pr-8 shadow transition-all duration-500 ${scroll ? "h-14" : "h-20"}`}>
			<Link to="/">
				<img src={ logo } alt="Logo" className={`m-2 object-contain transition-all duration-500 ${scroll ? "h-10" : "h-14"}`}></img>
			</Link>
			<Nav scroll={ scroll } />
		</header>
	);
};

export default Header;