import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import useLoggedIn from "../hooks/useLoggedIn";
import useLogout from "../hooks/useLogout";
import LoginModal from "./LoginModal";
import { useLocation } from "@reach/router";



const Nav = ({scroll}) => {

	const firstCategoryUrl = useStaticQuery(graphql`
        query Products {
            allCategories(limit: 1, sort: {fields: category}) {
                nodes {
                    categorySlug
                }
            }
        }
    `).allCategories.nodes;

	const navItems = [
		{
			caption: "Inicio",
			href: "/"
		},
		{
			caption: "Productos",
			href: "/categoria/" + firstCategoryUrl[0].categorySlug
		},
		// {
		//     caption: "Empresa",
		//     href: "/empresa"
		// },
		// {
		//     caption: "Contacto",
		//     href: "/contacto"
		// }
	];

	const isLoggedIn = useLoggedIn();
	const { auth } = useAuth();
	const logout = useLogout();
	const [isLoggedInState, setIsLoggedInState] = useState(auth?.user);
	const [showBurguerMenu, setShowBurguerMenu] = useState(false);

	const location = useLocation().pathname;

	useEffect(() => {
		const checkIfLoggedIn = async () => await isLoggedIn();
		checkIfLoggedIn();
	}, []);

	useEffect(() => setIsLoggedInState(auth?.user), [auth]);

	const handleBurguerMenu = () => setShowBurguerMenu(showBurguerMenu => !showBurguerMenu);

	return (
		<nav className="flex items-center">
			<ul className="hidden lg:block">
				{
					navItems.map((item, index) => {
						const isCurrentPage = location === item.href || (item.href === "/productos" && location.indexOf("/categoria") > -1);
						const currentClass = isCurrentPage ? "bg-blue-700 text-white" : "text-blue-900";
						return (
							<Link  to={ item.href } key={ index }>
								<li className={ `px-6 inline-block py-2 rounded-md ${currentClass} hover:bg-blue-700 hover:text-white hover:transition-all duration-200` }>{ item.caption }</li>
							</Link>
						);
					})
				}

				{
					isLoggedInState
						? <button onClick={logout} className="px-6 inline-block py-2 rounded-md text-blue-900 hover:bg-blue-700 hover:text-white hover:transition-all duration-200">Cerrar sesión</button>
						: <LoginModal collapsedStyle={false}/>
				}


			</ul>

			<div className="mr-4">
				<Link  to="/busqueda">
					<button className="align-middle inline-block p-2 rounded-md text-blue-900 hover:bg-blue-700 hover:text-white hover:transition-all duration-200">
						{/* Hero Icons */}
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
							<path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
						</svg>
					</button>
				</Link>
			</div>

			<div onClick={handleBurguerMenu} className="lg:hidden">
				<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
					<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
				</svg>
			</div>

			{
				showBurguerMenu &&
				<ul className={`lg:hidden absolute ${scroll ? "top-14" : "top-20"} left-0 block bg-white w-full text-center`} >
					{
						navItems.map((item, index) => (
							<Link  to={ item.href } key={ index }>
								<li className="px-6 block py-4 text-blue-900 active:bg-blue-700 active:text-white active:transition-all duration-200">{ item.caption }</li>
							</Link>
						))
					}
					{
						isLoggedInState
							? <li onClick={logout} className="px-6 block py-4 text-blue-900 active:bg-blue-700 active:text-white active:transition-all duration-200">Cerrar sesión</li>
							: <LoginModal collapsedStyle={true}/>
					}
				</ul>
			}
		</nav>
	);
};

export default Nav;