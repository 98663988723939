import React, { Fragment } from "react";

const Modal = ({title, isOpen, setModalIsOpen, collapsedStyle, children}) => {

	const onButtonClick = () => {
		setModalIsOpen(true);
	};

	const onOutsideClick = (e) => {
		if(e.target.id === "modal" || e.target.id === "close-modal") {
			setModalIsOpen(false);
		}
	};
	const onCloseClick = () => {
		setModalIsOpen(false);
	};

	return (
		<Fragment>
			{
				collapsedStyle
					? <li className="px-6 block py-4 text-blue-900 active:bg-blue-700 active:text-white active:transition-all duration-200" onClick={(e) => onButtonClick(e)}>{title}</li>
					: <button className="px-6 inline-block py-2 rounded-md text-blue-900 hover:bg-blue-700 hover:text-white hover:transition-all duration-200" onClick={(e) => onButtonClick(e)}>{title}</button>
			}

			<div id="modal" onClick={(e) => onOutsideClick(e)} className={"fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4" + (isOpen ? "" : " hidden")}>
				<div className="relative top-40 mx-auto shadow-lg rounded-md bg-white w-96 h-96">
					<button onClick={(e) => onCloseClick(e)} id="close-modal" className="absolute right-3 mt-2 text-gray-600">x</button>
					{ children }
				</div>
			</div>
		</Fragment>

	);
};

export default Modal;