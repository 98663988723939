import { Link } from "gatsby";
import React from "react";
import { useLocation } from "@reach/router";

const SidebarMenu = (props) => {

	const location = useLocation();

	return (
		<div className="h-fit w-96 mr-4 p-6 pl-12">

			<h1 className="font-semibold uppercase text-xl">Categorías</h1>
			<hr className="w-full mt-2 mb-8 border-gray-200 border-x-2"></hr>
			{
				props.items.map((item, index) => {
					const href = `/categoria/${item.categorySlug}`;
					const isActive = location.pathname === href;
					return (
						<Link to={ href } key={ index }>
							<p className={ "my-4 capitalize hover:text-blue-500 " + (isActive ? "font-semibold text-md" : "text-sm") }>
								{ item.category.toLowerCase() }
							</p>
						</Link>
					);
				})
			}

		</div>
	);
};

export default SidebarMenu;