import React from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import LoginForm from "./LoginForm";
import Modal from "./Modal";

const LoginModal = ({collapsedStyle}) => {

	const [isOpenState, setIsOpenState] = useStateIfMounted(false);

	const setModalIsOpen = (value) => {
		setIsOpenState(value);
	};

	const handleSuccess = () => {
		setIsOpenState(false);
	};

	return (
		<Modal title="Ingresar" isOpen={isOpenState} setModalIsOpen={setModalIsOpen} collapsedStyle={collapsedStyle}>
			<LoginForm onSuccess={handleSuccess} />
		</Modal>
	);
};

export default LoginModal;