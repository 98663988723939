import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {

	const refreshToken = useRefreshToken();
	const { auth } = useAuth();

	useEffect(() => {

		/**
         *  Intercepta las requests y le pone el access token si no lo tiene
         */
		const requestIntercept = axiosPrivate.interceptors.request.use(
			async config => {
				if(!config.headers["Authorization"]) { // Is first request
					config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
				}
				return config;
			},
			(error) => Promise.reject(error)
		);

		/**
         *  Intercepta las responses y si tiene error 401 Unauthorized, intenta actualizar el access token y volver a intentar la request
         */
		const responseIntercept = axiosPrivate.interceptors.response.use(
			response => response,
			async (error) => {
				const previousRequest = error?.config;
				if(error?.response?.status === 401 && !previousRequest?.sent) {
					previousRequest.sent = true;
					const newAccessToken = await refreshToken();
					previousRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
					return axiosPrivate(previousRequest);
				}
				return Promise.reject(error);
			}
		);

		return () => {
			axiosPrivate.interceptors.request.eject(requestIntercept); // Clean up interceptors
			axiosPrivate.interceptors.response.eject(responseIntercept); // Clean up interceptors
		};
	}, [auth, refreshToken]);

	return axiosPrivate;
};

export default useAxiosPrivate;