import React from "react";

const Footer = () => {
	return (
		<footer className="h-36 bg-blue-900 flex justify-center items-center text-white">
			<div>© 2021 Belimport S.A</div>
		</footer>
	);
};

export default Footer;