import axios from "../api/axios";
import React from "react";
import useAuth from "../hooks/useAuth";
import { useStateIfMounted } from "use-state-if-mounted";

const LOGIN_URL = "/wp-json/api-bearer-auth/v1/login";

const LoginForm = ({onSuccess}) => {

	const [user, setUser] = useStateIfMounted("");
	const [password, setPassword] = useStateIfMounted("");
	const [errorMessage, setErrorMessage] = useStateIfMounted("");
	const { setAuth } = useAuth();
	const [isLoading, setIsLoading] = useStateIfMounted(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		try {
			const response = await axios.post(LOGIN_URL,
				{
					username: user,
					password: password
				},
				{
					headers: {"Content-type": "application/json"},
					withCredentials: true
				}
			);

			const accessToken = response?.data?.access_token;

			setAuth({ user, accessToken });
			setUser("");
			setPassword("");
			setErrorMessage("");

			onSuccess();

		} catch(err) {
			if(!err?.response) {
				setErrorMessage("No hubo respuesta del servidor");
			} else if(err.response?.status === 401) {
				setErrorMessage("Usuario o contraseña incorrecta");
			} else {
				setErrorMessage("No se pudo iniciar sesión");
			}
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};


	return (
		<form onSubmit={handleSubmit} className="flex flex-col justify-center items-center h-full w-80 mx-auto">
			<h3 className='text-blue-900 font-bold text-center text-xl mb-8'>Bienvenido</h3>

			<label htmlFor='username' className='my-2'>Usuario</label>
			<input
				type='text'
				id='username'
				autoComplete="off"
				onChange={(e) => setUser(e.target.value)}
				value={user}
				required
				className="border border-gray-300 w-full h-10 my-2 pl-1"
			/>

			<label htmlFor='password' className='my-2'>Contraseña</label>
			<input
				type='password'
				id='password'
				onChange={(e) => setPassword(e.target.value)}
				value={password}
				required
				className="border border-gray-300 w-full h-10 my-2 pl-1"
			/>

			<button disabled={isLoading} className="disabled:opacity-70 bg-blue-700 text-white px-4 py-2 rounded-md mt-4 hover:bg-blue-700 transition">Ingresar</button>
			<span>{ errorMessage }</span>
		</form>
	);
};

export default LoginForm;