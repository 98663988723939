import React from "react";

const FeatureCards = () => {

	const content = [
		{
			imageUrl: process.env.GATSBY_WORDPRESS_BASE_URL + "/wp-content/uploads/feature-cards/feature-card-01.jpg"
		},
		{
			imageUrl: process.env.GATSBY_WORDPRESS_BASE_URL + "/wp-content/uploads/feature-cards/feature-card-02.jpg"
		}
	];
	return (
		<div className="flex flex-col md:flex-row justify-between md:space-x-4 w-full">

			{
				content.map((item, index) => {
					return (
						<div key={ index } className="flex-col items-center w-full md:w-1/2 mt-6 bg-slate-300">
							<img className="block w-full h-auto" src={ item.imageUrl } />
						</div>
					);
				})
			}

		</div>
	);
};

export default FeatureCards;