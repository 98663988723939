import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import SidebarMenu from "./SidebarMenu";

const Sidebar = () => {

	const categoryCollection = useStaticQuery(graphql`
        query CategoriesQuery {
            allCategories(sort: {fields: category}) {
                nodes {
                    category
                    categorySlug
                }
            }
        }
    `).allCategories.nodes;

	return (
		<SidebarMenu items={ categoryCollection } />
	);
};


export default Sidebar;