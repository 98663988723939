import useAxiosPrivate from "./useAxiosPrivate";

/**
 *
 * Retorna funcion que permite validar contra el servidor que el usuario esta logueado y que las credenciales están vigentes
 */
const useLoggedIn = () => {

	const VALIDATE_TOKEN_URL = "/wp-json/wp/v2/settings";

	const axiosPrivate = useAxiosPrivate();

	const isLoggedIn = async () => {
		let loggedIn = false;
		try {
			const response = await axiosPrivate(VALIDATE_TOKEN_URL);
			console.log(response);
			loggedIn = true;

		} catch(error) {
			loggedIn = false;
			console.log(error);
		}

		return loggedIn;
	};

	return isLoggedIn;
};

export default useLoggedIn;