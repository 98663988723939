import React from "react";

const Slider = () => {

	const content = [
		{
			imageUrl: process.env.GATSBY_WORDPRESS_BASE_URL + "/wp-content/uploads/home-slider/home-slider-01.jpg"
		},
		{
			imageUrl: process.env.GATSBY_WORDPRESS_BASE_URL + "/wp-content/uploads/home-slider/home-slider-02.jpg"
		},
		{
			imageUrl: process.env.GATSBY_WORDPRESS_BASE_URL + "/wp-content/uploads/home-slider/home-slider-03.jpg"
		}
	];

	return (
		<div className="flex w-full space-x-4">
			<div className="flex justify-center items-center w-full h-full bg-slate-200">
				<img alt="Slider 1" className="w-full" src={content[0].imageUrl} />
			</div>
			<div className="hidden sm:flex justify-center items-center w-full h-full bg-slate-200">
				<img alt="Slider 2" className="w-full" src={content[1].imageUrl} />
			</div>
			<div className="hidden lg:flex justify-center items-center w-full h-full bg-slate-200">
				<img alt="Slider 3" className="w-full" src={content[2].imageUrl} />
			</div>
		</div>
	);
};

export default Slider;