import axios from "../api/axios";
import useAuth from "./useAuth";

const LOGOUT_URL = "/wp-json/api-bearer-auth/v1/logout";

const useLogout = () => {

	const { setAuth } = useAuth();

	const logout = async () => {
		setAuth({});
		try {
			await axios(LOGOUT_URL, {
				withCredentials: true
			});
		} catch(err) {
			console.error(err);
		}
	};

	return logout;
};

export default useLogout;